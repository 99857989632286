#ccpContainer {
  width: 350px;
  height: 500px;
  position: relative;
}

#block-clear-contact {
  position: absolute;
  width: 150px;
  height: 50px;
  left: 100px;
  top: 445px;
  background: #f2f2f2;
}

.ui.accordion.styled {
  position: relative;
  margin-left: 1px;
  height: 500px;
  width: 500px;
}

.content {
  height: 335px;
  overflow: auto;
}

#root {
  margin-left: 13px;
}
